.location {
  font-size: larger;
  padding-right: 10px;
}

.select-location {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -160px;
  margin-top: 10px;
}

.loading {
  display: flex;
  justify-content: center;
}
